import React from "react";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Link
} from "@mui/material";
import telegramChat from "../../../image/telegramChat.png"
import { useAppSelector } from "../../../hooks/redux";
import { useAction } from "../../../hooks/actions";

const SidebarListItem = ({
  open,
  handleOpen = () => { },
  icon: Icon,
  text,
  to,
  selected,
  subItems,
  permissions,
  expanded,
  level,
  action,
  index,
  openIndex,
  setCurrentOpen,
  isLast,
  isopenSidebar,
}) => {

  const navigate = useNavigate();
  const location = useLocation();
  const { isChangeForm } = useAppSelector((state) => state.organizations);
  const { setPathForModalClosePage } = useAction();
  // Состояние для хранения текущего открытого индекса
  const handleChangePage = (to) => {
    if (action) {
      action();
    }
    if (isChangeForm) {
      setPathForModalClosePage(to);
      return;
    }
    navigate(to);
  };

  const handleCLickItem = () => {
    handleOpen();
    handleChangePage(to);

  };
  const handleSummary = () => {
    if (setCurrentOpen)
      setCurrentOpen(openIndex === index ? null : index);
  };



  const getColor = ({ level, expanded, open }) => {
    if (selected && !expanded && level === 1) return "var(--text-color)";
    if (selected && level === 2) return "var(--accent)";
    if (expanded && level === 1 && open) return "var(--text-color)";

    return "var(--text-color)";
  };
  const openAccordion = openIndex === index;
  return (
    <Accordion
      className={`sidebar-list ${open ? "sidebar-opened-list" : "sidebar-closed-list"}`}
      square={true}
      expanded={openAccordion}
      disableGutters={true}
      onClick={() =>
        expanded ? !open && handleCLickItem() : handleChangePage(to)
      }
    >
      <AccordionSummary
        className={`sidebar-list-row ${open ? "sidebar-opened-list-row" : "sidebar-closed-list-row"}`}
        sx={{
          backgroundColor:
            open && selected && !expanded ? "var(--bgListItem)" : "",
          borderRadius: open && selected ? "32px" : "",
          marginTop: open && !expanded && level === 2 ? "16px" : "",
          marginLeft: level === 2 ? '20px' : '',
          color: open && selected && !expanded ? 'var(--accent)' : ''
        }}
        onClick={() => handleSummary()}
        focusVisibleClassName="test-focus"
      >
        <Box
          className={`sidebar-list-row-content ${(selected && expanded) || (selected & !expanded && level === 1) ? 'expanded' : ''}`}
          sx={{
            display: 'flex',
            justifyContent: open && level === 2 ? "initial" : "",
            padding: !Icon && open ? "12px 19px" : "12px 19px",
            margin: open ? "inherit" : "0 auto",
            color: selected ? 'var(--accent)' : 'var(--text-color)'
          }}
        >
          {Icon && (
            <Box
              className="sidebar-list-row-content-icon"
            >
              <Icon />
            </Box>
          )}
          <Typography
            className={`sidebar-list-row-content-text ${expanded && selected ? "expended" : ""
              }`}
            sx={{
              display: open ? "block" : "none",
              color: getColor({ level, expanded, open }),
            }}
          >
            {text}
          </Typography>
          {(isopenSidebar && isLast) &&
        <Link href="https://t.me/VDPOpro" target="_blank" rel="noopener noreferrer">
          <Box
            component="img"
            src={telegramChat}
            sx={{
              height: "68px",
              position: "absolute",
              right: "0px",
              zIndex: 999999,
              bottom: "0px"
            }}
          />
        </Link>
      }
        </Box>
      </AccordionSummary>
      {expanded && open && (
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "0",
          }}
        >
          {subItems
            .filter((item) => permissions.includes(item.name))
            .map(({ id, title, subItems, path, expanded, level }) => (
              <SidebarListItem
                key={id}
                text={title}
                to={path}
                open={true}
                selected={location?.pathname?.includes(path)}
                subItems={subItems}
                expanded={expanded}
                level={level}
                action={action}
              />
            ))}
        </AccordionDetails>
      )}
    </Accordion>
  );
};

export default SidebarListItem;
