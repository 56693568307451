import React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import {
  objectsToTestConfig
} from "../../form-config";
import { formFields } from "../../form-config";
import FormItem from "../../../../components/FormItem";

export const FunctionalForm = ({ control, errors, config }) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: "24px",
            width: "50%",
          }}
        >
          {objectsToTestConfig.map(
            ({ id, name, label, input, ...rest }) => {
              return (
                <FormItem
                  key={id}
                  control={control}
                  errors={errors}
                  name={
                    name === formFields.roleId
                      ? name
                      : `${[formFields.permissionsIds]}.${name}`
                  }
                  label={label}
                  input={input}
                  {...rest}
                />
              );
            }
          )}
        </Box>
      </Box>

      <Typography
        className="input-block-title"
        sx={{
          fontSize: "16px !important",
          fontWeight: "500 !important",
          mb: "10px !important",
          color: "var(--headers) ",
        }}
      >
        {t("title:role_settings")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          border: "1px solid var(--border)",
          borderRadius: "16px",
          padding: "16px",
          width: "50%",
        }}
      >
        {config.map(({ name, label, input, ...rest }) => {
          return (
            <FormItem
              key={name}
              control={control}
              errors={errors}
              name={`${[formFields.permissionsIds]}.${name}`}
              label={label}
              input={input}
              {...rest}
            />
          );
        })}
      </Box>
    </Box>
  );
};
