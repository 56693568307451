import React, { useEffect, useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import { Box, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ModalConfirmPage } from "../../../components/Modals/ModalConfirmPage";
import "./styles/styles.css";
import { formConfigSection, sections } from "./form-config";
import {
  useEditTaskMutation,
  useGetTaskQuery,
  useSignAndSendMutation,
  useLazyGetTaskLazyQuery
} from "../../../../middleware/tasksApi";
import { useAction } from "../../../../hooks/actions";
import { STATUS_ID, PRIORITY_ID, STATUS_NAME, fields } from "../constants";
import { useAppSelector } from "../../../../hooks/redux";
import { DEFAULT_ROLE_ID, ROLES, ROLE_TYPES } from "../../../../constants";
import {
  getConfigByRole,
  getRequestData,
  getRequestDataFilesForAdd,
} from "./helpers";
import { formFields } from "./options";
import { useManipulateFilesMutation } from "../../../../middleware/apiUploadFile";
import { useDeleteFacilityMutation } from "../../../../middleware/facilitiesApi";
import DefectModal from "./DefectModal/DefectModal";
import AccordionSection from "./AccordionSection";
import SectionForm from "./SectionForm";
import { useMemo } from "react";
import closeicon from "../../../../image/icons/closeInputFile.png"

const TaskDialog = ({ open, setOpen, taskId, refetch, resetForm }) => {
  const { t } = useTranslation();

  const { info } = useAppSelector((state) => state.users);
  const { roleId, phoneVerified } = info || {};
  const roleUser = ROLES[roleId];

  const isHidden =
    roleUser === ROLE_TYPES.DIGITIZER || roleUser === ROLE_TYPES.EMPLOYEE;
  const { setIsErrorSubmit, setIsSuccessSubmit, setIsErrorRequest } =
    useAction();
  const {
    data: task,
    error: errorGetTask,
    isLoading: isLoadingTask,
    refetch: refetchtask
  } = useGetTaskQuery(taskId, {
    refetchOnMountOrArgChange: true,

  });

  const [
    signAndSend,
    {
      error: errorSingAndSend,
      isSuccess: isSuccessSingAndSend,
      isLoading: isLoadingSing,
    },
  ] = useSignAndSendMutation();
  const [isBeginWork, setisBeginWork] = useState(false);
  const [isUpdateTask, setisUpdateTask] = useState(false);
  const [isStartWork, setisStartWork] = useState(false);
  const [PrevselectedValues, setPrevselectedValues] = useState([]);
  const [OpenDefectModal, setOpenDefectModal] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [expanded, setExpanded] = useState(JSON.parse(localStorage.getItem('accordionTaskDialog')) || []);
  const [getTask, { error: errorgetTask, isSuccess: isSuccessgetTask, isLoading: isLoadinggetTask }] =
    useLazyGetTaskLazyQuery();
  const [editTask, { error: errorEdit, isSuccess: isSuccessEdit, isLoading: isLoadingEdit }] =
    useEditTaskMutation();
  const [manipulateFiles, { error: errorFile, isSuccess: isSuccessFile, isLoading: isLoadingFiles }] =
    useManipulateFilesMutation();
  const [deleteFacility] = useDeleteFacilityMutation();
  const {
    control,
    reset,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    watch,
  } = useForm({
    mode: "onChange",
  });
  const isCompletelyStatus = useMemo(() => {
    return task
      ? STATUS_ID[task?.status] === STATUS_ID[fields.completely]
      : false;
  }, [task]);

  const errorSubmit = errorEdit || errorFile || errorSingAndSend;
  const isSuccess = isSuccessEdit || isSuccessFile || isSuccessSingAndSend;
  const isLoading = isLoadingEdit || isLoadingFiles;

  const handleRefetch = async (refetch) => {
    refetch
      ? refetch().then(() => {
        resetForm && resetForm();
        handleClose();
      })
      : handleClose();
  };

  const onSubmit = async (data, e) => {
    setOpenConfirm(false)
    const { entities, jobFiles } = getRequestData(data);

    editTask(entities).then((result) => {
      if ("error" in result) {
        return;
      }
      const id = result?.data?.id;
      const requestDataFiles = getRequestDataFilesForAdd(id, jobFiles);

      manipulateFiles(requestDataFiles).then((response) => {
        if ("error" in response) {
        }
      });
      getTask(taskId, {
        skip: !taskId,
      }).then(() => {
        reset({
          ...data,
          status: data.status,
          priority: data.priority
        });
        handleRefetch(refetch);
        setisBeginWork(true);
      })
    });
  };

  const clickBtnSignAndSend = () => {
    signAndSend({ taskId }).then(() => {
      handleRefetch(refetch);
    });
  };

  const handleClose = () => {

    setOpen(false);
  };

  useEffect(() => {
    if (task) {
      const { jobFiles } = task || {};
      const files =
        jobFiles &&
        jobFiles.reduce((acc, item) => {
          return {
            ...acc,
            filesPrev: acc?.filesPrev ? [...acc.filesPrev, item] : [item],
            filesToAdd: [],
            filesToDelete: [],
          };
        }, {});

      const equipmentsCoordinates =
        task.floorSchemeEquipmentAndRoomCoordinates?.equipmentCoordinates || [];
      reset({
        ...task,
        protectionObjectId: task.protectionObjectId,
        status: STATUS_ID[`${task.status}`],
        priority: PRIORITY_ID[`${task.priority}`],
        jobFiles: files,
        equipmentsCoordinates,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task, reset]);

  useEffect(() => {
    if (isUpdateTask) {
      getTask(taskId, {
        skip: !taskId,
      }).then(({ data }) => {
        reset({
          ...data,
          status: data.status,
          priority: data.priority
        });
      })
      setisUpdateTask(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateTask]);

  useEffect(() => {
    setIsErrorRequest(errorGetTask);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorGetTask]);

  useEffect(() => {
    setIsErrorSubmit(errorSubmit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorSubmit]);

  useEffect(() => {
    setIsSuccessSubmit(isSuccess);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const handleChange = (panel) => (event, isExpanded) => {
    let res = []
    if (isExpanded) {
      res = [...expanded, panel];
    } else {
      res = expanded.filter((item) => item !== panel);
    }
    localStorage.setItem('accordionTaskDialog', JSON.stringify(res));
    setExpanded(res)
  };
  const saveConfirm = () => {
    setisStartWork(true);
    if (getValues(formFields.status) === 2 && getValues(formFields.jobChecklist).filter((item) => !item.isCompleted).length > 0) {
      setOpenConfirm(true)
    } else {
      handleSubmit(onSubmit)()
    }
  }
  sections({ control, isHidden, isCompletelyStatus }).map(
    ({ id, title, hidden, Component, config }) =>{
      console.log("task",task?.jobEquipments !=[] && title == "equipments")
    })
 
  return (
    <Dialog className="task_dialog" open={open} onClose={handleClose}>
      <Box sx={{display:"flex",textAlign:"center"}}>
      <Typography className="task_dialog-title" sx={{}}>
        {task?.name || ""}
      </Typography>
      <IconButton
        sx={{
          position: "absolute",
          top: "34px",
          right: "34px",
          color: "black",
        }}
        onClick={handleClose}
      >
        <Box
            component="img"
            src={closeicon}
          />
      </IconButton>
      </Box>
      


      <form
        id="myform"
        onSubmit={handleSubmit(onSubmit)}
        className="scrolled-form-view"
      >
        <Box className="task_dialog-header">


          <Box className="task_dialog-head_buttons">
            {isHidden && (
              <>
                <Button
                  form="myform"
                  className="btn btn-outlined-primary"
                  type="submit"
                  onClick={() => { setValue(formFields.status, STATUS_ID[fields.inwork]) }}
                  disabled={isBeginWork || STATUS_ID[task?.status] === STATUS_ID[fields.inwork] || isLoading || isCompletelyStatus}
                >
                  {t("button:work_started")}
                </Button>

                <Button
                  className="btn btn-outlined-primary"
                  disabled={!phoneVerified || task?.currentUserSigned || isCompletelyStatus}
                  onClick={clickBtnSignAndSend}
                >
                  {t("button:sign_and_sent")}
                </Button>
              </>
            )}
            {task?.enableFormDefectAct &&
              <Button
                className="btn btn-primary-fill"
                onClick={() => { setOpenDefectModal(true) }}
              >
                {t("button:generatedefectAct")}
              </Button>
            }

            <Button
              form="myform"
              className="btn btn-primary-fill"
              onClick={saveConfirm}
              disabled={(isSubmitSuccessful && !errorSubmit) || isCompletelyStatus}
            >
              {t("button:save")}
            </Button>
          </Box>
        </Box>
        <ModalConfirmPage
          open={openConfirm}
          setOpen={setOpenConfirm}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
        />

        <Box sx={{ paddingBottom: "16px" }}>
          <Box className="task_dialog-sub_title">
            <Typography>{`${t("label:current_status")}:`}</Typography>
            <Typography variant="span">
              {STATUS_NAME[watch(formFields.status)]}
            </Typography>
          </Box>
          <SectionForm
            control={control}
            errors={errors}
            data={task}
            refetch={setisUpdateTask}
            config={getConfigByRole({
              roleId: roleId || DEFAULT_ROLE_ID,
              config: formConfigSection,
              isCompletelyStatus
            })}
          />
        </Box>
        {sections({ control, isHidden, isCompletelyStatus }).map(
          ({ id, title, hidden, Component, config }) =>
            (!hidden && (task?.jobChecklist.length != 0 && title =="check_list") || (task?.jobEquipments.length != 0 && title =="equipments") || (title !="check_list" && title !="equipments")) && (
              <AccordionSection key={id} title={title} idd={title} expandedAccordions={expanded} handleAccordionChange={handleChange}>
                {Component && (
                  <Component
                    id={id}
                    control={control}
                    errors={errors}
                    data={task}
                    taskId={taskId}
                    getValues={getValues}
                    setValue={setValue}
                    params={{
                      responsibleOrganizationId: watch(
                        formFields.responsibleOrganizationId
                      ),
                    }}
                    config={getConfigByRole({
                      roleId: roleId || DEFAULT_ROLE_ID,
                      config,
                      isCompletelyStatus,
                    })}
                  />
                )}
              </AccordionSection>
            )
        )}
      </form>
      {OpenDefectModal &&
        <DefectModal
          open={OpenDefectModal}
          setOpen={setOpenDefectModal}
          defectData={{ responsibleCustomerUserId: task?.responsibleCustomerUserId, executiveUserId: task?.executiveUserId, protectionObjectName: task?.protectionObjectName, protectionObjectId: task?.protectionObjectId, equipmentData: task?.jobEquipments }}
          PrevselectedValues={PrevselectedValues}
          setPrevselectedValues={setPrevselectedValues}
        />
      }
      <Backdrop
        sx={{ color: "var(--white)", zIndex: 9999 }}
        open={isLoadingTask || isLoadingSing}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
};

export default TaskDialog;
